import React, { Fragment, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Link,
  Box,
  Typography
} from '@material-ui/core';
import * as ROUTES from '../../constants/routes';
import logo from './logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { navigate, Link as GatsbyLink } from 'gatsby';
import { FirebaseClient } from '../../components/Firebase/Client';
import { SessionContext } from '../../components/Session/Context';
import { AuthUser, OrganizationFirebase } from '../../types/modelsFirebase';
import { withFirebase } from '../../components/Firebase/withFirebase';
import { Locality } from '../../types/models';
import { withLocation } from '../../components/withLocation';
import { compose } from 'recompose';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: theme.colors.oxfordBlue05,
      boxShadow: theme.shadow.mediumOxfordBlue02
    },
    toolbar: {
      minHeight: theme.spacing(15)
    },
    createViewLogo: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    localitiesInput: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      color: theme.colors.white,
      lineHeight: 'unset'
    },
    localitiesSelectWrapper: {
      maxWidth: '280px',
      paddingRight: theme.spacing(12),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(6)
      }
    },
    localitiesIcon: {
      color: theme.colors.white
    },
    localitiesList: {
      backgroundColor: theme.colors.oxfordBlue,
      borderRadius: '0px 0px 4px 4px',
      boxShadow: theme.shadow.mediumOxfordBlue02
    },
    localitiesListItem: {
      fontSize: theme.typography.h3.fontSize,
      color: theme.colors.white
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    selectInputRootSelected: {
      backgroundColor: (props: { localitiesSelectDisplay: boolean }) =>
        props.localitiesSelectDisplay ? theme.colors.oxfordBlue : 'unset',
      '&:focus': {
        backgroundColor: (props: { localitiesSelectDisplay: boolean }) =>
          props.localitiesSelectDisplay ? theme.colors.oxfordBlue : 'unset'
      }
    },
    selectInputNotchedOutline: {
      border: 'unset'
    },
    title: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3)
      }
    },
    companyNameLink: {
      textDecoration: 'none',
      '&:visited': {
        color: theme.colors.white
      }
    },
    logoclass: {
      width: '143px',
      height: '30px'
    }
  })
);

interface NavBarBaseProps {
  firebase: FirebaseClient;
  authUser: AuthUser;
  search: { localityId: string };
}

// Display logo if it's available. Otherwise display organization name as a text.
interface OrganizationLogoInfo {
  organizationName: string | null;
  logoUrl: string | null;
}

const NavBarBase = ({ firebase, authUser, search }: NavBarBaseProps) => {
  const [localities, setLocalities] = useState<Locality[] | undefined>(undefined);
  const [locality, setLocality] = useState<string>('unset');
  const [localitiesSelectDisplay, setLocalitiesSelectDisplay] = React.useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [organizationLogoInfo, setOrganizationLogoInfo] = useState<OrganizationLogoInfo>({
    organizationName: null,
    logoUrl: null
  });
  const menuOpen = Boolean(menuAnchorEl);
  const classes = useStyles({ localitiesSelectDisplay });

  const getOrganizationLogo = (organizationId: string) => {
    firebase
      .getOrganizationSnapshot(organizationId)
      .then((organizationSnapshot) => {
        const organization = organizationSnapshot.data() as OrganizationFirebase;
        if (organization.logoPath) {
          firebase
            .getStorageUrlForPath(organization.logoPath)
            .then((url) => {
              setOrganizationLogoInfo({ organizationName: null, logoUrl: url });
            })
            .catch(() => {
              //path points to non-existing resource
              setOrganizationLogoInfo({ organizationName: organization.name, logoUrl: null });
            });
        } else {
          setOrganizationLogoInfo({ organizationName: organization.name, logoUrl: null });
        }
      })
      .catch((reason) => {
        throw new Error(reason);
      });
  };

  const firebaseInit = async () => {
    if (authUser.user && authUser.organizationId && authUser.localityIds) {
      getOrganizationLogo(authUser.organizationId);

      const localitiesData = await firebase.getLocalities(authUser.organizationId, authUser.localityIds);
      setLocalities(localitiesData);
      if (search && search.localityId) {
        setLocality(search.localityId);
      }
    }
  };
  useEffect(() => {
    firebaseInit();
  }, [authUser]);

  const toggleLocalitiesDisplay = () => {
    setLocalitiesSelectDisplay(!localitiesSelectDisplay);
  };

  const handleLocalitySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLocality(event.target.value as string);
    navigate(`${ROUTES.LOCALITYDETAILS}/?localityId=${event.target.value}`);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSignOut = () => {
    handleMenuClose();
    if (firebase) {
      firebase.doSignOut();
    }
  };

  //@ts-ignore
  const RouterLink = React.forwardRef((props, ref) => <GatsbyLink innerRef={ref} {...props} />);

  // Display logo if it's available. Otherwise display organization name as a text.
  const organizationLogo = (logoInfo: OrganizationLogoInfo): JSX.Element | null => {
    if (logoInfo.logoUrl != null) {
      return (
        <GatsbyLink to={ROUTES.HOME}>
          <img alt="company logo" src={logoInfo.logoUrl} />
        </GatsbyLink>
      );
    } else if (logoInfo.organizationName) {
      return (
        <GatsbyLink to={ROUTES.HOME} className={classes.companyNameLink}>
          <Typography variant="h3">{logoInfo.organizationName}</Typography>
        </GatsbyLink>
      );
    }

    return null;
  };

  return (
    <AppBar classes={{ root: classes.appbar }} position="static">
      <Toolbar classes={{ root: classes.toolbar }}>
        <Box className={classes.title}>
          <GatsbyLink to={ROUTES.HOME} className={classes.createViewLogo}>
            <img alt="logo" className={classes.logoclass} src={logo} />
          </GatsbyLink>
          {authUser && authUser.user && organizationLogo(organizationLogoInfo)}
        </Box>
        {authUser && authUser.user && (
          <Fragment>
            <FormControl classes={{ root: classes.localitiesSelectWrapper }}>
              {localities && localities.length > 1 && (
                <Select
                  onOpen={toggleLocalitiesDisplay}
                  onClose={toggleLocalitiesDisplay}
                  classes={{
                    select: classes.selectInputRootSelected
                  }}
                  value={locality}
                  onChange={handleLocalitySelect}
                  IconComponent={() => (
                    <ExpandMoreIcon
                      role="presentation"
                      className="MuiSelect-icon"
                      classes={{ root: classes.localitiesIcon }}
                    />
                  )}
                  input={
                    <OutlinedInput
                      classes={{
                        root: classes.localitiesInput,
                        notchedOutline: classes.selectInputNotchedOutline
                      }}
                      labelWidth={0}
                    />
                  }
                  MenuProps={{
                    classes: { paper: classes.localitiesList },
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 61, horizontal: 'center' },
                    transformOrigin: { vertical: 'top', horizontal: 'center' },
                    keepMounted: true
                  }}
                >
                  <MenuItem style={{ display: 'none' }} value="unset" disabled>
                    {Localization.getInst().localizedString(LocalizationKey.navBarSelectLocation)}
                  </MenuItem>
                  {localities.map((locality) => (
                    <MenuItem
                      key={locality.localityId}
                      classes={{ root: classes.localitiesListItem }}
                      value={locality.localityId}
                    >
                      {locality.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            <IconButton
              edge="start"
              classes={{ root: classes.menuButton }}
              color="inherit"
              aria-label="Menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 61, horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              open={menuOpen}
              onClose={handleMenuClose}
            >
              {/* 
  // @ts-ignore */}
              <Link
                color="textPrimary"
                variant="h3"
                underline="none"
                component={RouterLink}
                to={ROUTES.LANGUAGE}
                onClick={() => handleMenuClose()}
              >
                <MenuItem>{Localization.getInst().localizedString(LocalizationKey.menuLanguage)}</MenuItem>
              </Link>
              {/* 
  // @ts-ignore */}
              <Link
                color="textPrimary"
                variant="h3"
                underline="none"
                component={RouterLink}
                to={ROUTES.ACCOUNT}
                onClick={() => handleMenuClose()}
              >
                <MenuItem>{Localization.getInst().localizedString(LocalizationKey.menuMyAccount)}</MenuItem>
              </Link>
              {authUser.isModerator == true && (
                /* 
  // @ts-ignore */
                <Link
                  color="textPrimary"
                  variant="h3"
                  underline="none"
                  component={RouterLink}
                  to={ROUTES.ADMIN}
                  onClick={() => handleMenuClose()}
                >
                  <MenuItem>{Localization.getInst().localizedString(LocalizationKey.menuAdmin)}</MenuItem>
                </Link>
              )}
              <MenuItem onClick={() => handleSignOut()}>
                {Localization.getInst().localizedString(LocalizationKey.menuSignOut)}
              </MenuItem>
            </Menu>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

const NavBarBaseFirebase = withFirebase(NavBarBase);

const NavBarWithAuth = (props: { search: { localityId: string } }) => (
  <SessionContext.Consumer>
    {(authUser) => authUser && <NavBarBaseFirebase search={props.search} authUser={authUser} />}
  </SessionContext.Consumer>
);

export const NavBar = compose<{ search: { localityId: string } }, {}>(withLocation)(NavBarWithAuth);
