import React from 'react';
import { SessionContext } from './Context';
import { withFirebase } from '../Firebase/withFirebase';
import { FirebaseClient } from '../Firebase/Client';
import { Unsubscribe, User } from 'firebase';
import { AuthUser } from '../../types/modelsFirebase';

interface Props {
  firebase: FirebaseClient;
}

export const withAuthentication = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  class WithAuthentication extends React.Component<P & Props, AuthUser> {
    _initFirebase: boolean = false;
    unsubscribeListener: null | Unsubscribe = null;

    state: AuthUser = {
      authChecked: false,
      user: null,
      isModerator: false,
      organizationId: null,
      localityIds: null
    };

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;
        this.unsubscribeListener = this.props.firebase.onAuthUserListener(this.updateUser, this.forgetUser);
      }
    };

    updateUser = async (authUser: User) => {
      const idTokenResult = await authUser.getIdTokenResult(true);

      const localitiesClaim = idTokenResult.claims.localities;
      let localityIds = null;
      if (localitiesClaim) {
        localityIds = Object.keys(localitiesClaim)
          .filter((localityId: string) => localitiesClaim[localityId])
          .sort();
      }

      const hasChangedPassword =
        undefined !== idTokenResult.claims.hasChangedPassword ? idTokenResult.claims.hasChangedPassword : false;

      this.setState({
        authChecked: true,
        user: authUser,
        hasChangedPassword: hasChangedPassword,
        isModerator: idTokenResult.claims.moderator,
        organizationId: idTokenResult.claims.organizationId,
        localityIds: localityIds
      });
    };

    forgetUser = () => {
      this.setState({ authChecked: true, user: null, isModerator: false });
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this.unsubscribeListener !== null && this.unsubscribeListener();
    }

    render() {
      return (
        <SessionContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </SessionContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};
