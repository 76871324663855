export interface RemSpacing {
  (value1: number): string;
  (value1: number, value2: number): string;
  (value1: number, value2: number, value3: number): string;
  (value1: number, value2: number, value3: number, value4: number): string;
}

export const remSpacing = (...values: number[]): string => {
  const factor = 0.25;
  const transform = (value: number): string => `${value * factor}rem`;

  if (values.length == 0) {
    return transform(0);
  }

  if (values.length > 4) {
    return transform(0);
  }

  return values
    .map((value) => {
      return transform(value);
    })
    .join(' ');
};
