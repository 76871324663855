import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  fullHeight: {
    minHeight: '100%',
    overflow: 'hidden'
  }
});

interface BackgroundProps {
  children: JSX.Element;
}

export const Background: React.SFC<BackgroundProps> = ({ children }: BackgroundProps) => {
  const styles = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "background.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImage className={styles.fullHeight} Tag="section" fluid={imageData} backgroundColor={true}>
            {children}
          </BackgroundImage>
        );
      }}
    />
  );
};
