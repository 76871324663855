import { firestore, auth, functions, storage } from "firebase";
import { Config } from "./config";
import "firebase/storage";
import { Locality } from "../../types/models";
import { LocalityFirebase } from "../../types/modelsFirebase";
import { Timestamp } from "@google-cloud/firestore";
import { LocalityFirebaseValidator } from "../../types/modelsFirebaseValidation";

const config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
};

const ERROR_MSG_NO_CONFIRMATION_EMAIL_REDIRECT_URL = `
confirmation email redirect url not provided
`;

const ERROR_MSG_USER_NOT_LOGGED_IN = `
user not logged in
`;

const ERROR_MSG_FIREBASE_INIT_ERROR = `
failed while initializing firebase connection
`;

export class FirebaseClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firebaseNamespace: any;
  emailAuthProvider: auth.EmailAuthProvider;
  auth: auth.Auth;
  db: firestore.Firestore;
  functions: functions.Functions;
  storage: storage.Storage;

  //app is a namespace, its legacy and unsuported to be typed in babel-typescript implementation. Can't find a way to type it properly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(firebaseNamespace: any) {
    const app: firebase.app.App = firebaseNamespace.initializeApp(config);
    this.firebaseNamespace = firebaseNamespace;
    this.emailAuthProvider = firebaseNamespace.auth.EmailAuthProvider;
    this.auth = firebaseNamespace.auth();
    this.db = firebaseNamespace.firestore();
    this.functions = app.functions(Config.firebaseRegion);
    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
    const confirmationEmailRedirectUrl =
      process.env.CONFIRMATION_EMAIL_REDIRECT;
    if (!confirmationEmailRedirectUrl) {
      throw new Error(ERROR_MSG_NO_CONFIRMATION_EMAIL_REDIRECT_URL);
    }
    if (!this.auth.currentUser) {
      throw new Error(ERROR_MSG_USER_NOT_LOGGED_IN);
    }
    return this.auth.currentUser.sendEmailVerification({
      url: confirmationEmailRedirectUrl,
    });
  };

  doPasswordUpdate = (password: string) => {
    if (!this.auth.currentUser) {
      throw new Error(ERROR_MSG_USER_NOT_LOGGED_IN);
    }
    return this.auth.currentUser.updatePassword(password);
  };

  onAuthUserListener = (next: Function, fallback: Function) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (!authUser) return fallback();
      next(authUser);
    });

  getLocalities = async (
    organizationId: string,
    localityIds: string[]
  ): Promise<Locality[]> => {
    return new Promise((resolve, reject) => {
      const localityPromises = localityIds.map((localityId) => {
        return this.getLocalitySnapshot(organizationId, localityId);
      });

      Promise.all(localityPromises).then(
        (localitySnapshots) => {
          const localities: Locality[] = [];
          for (const localitySnapshot of localitySnapshots) {
            const localityFirebase = localitySnapshot.data() as LocalityFirebase;
            if (
              localityFirebase &&
              LocalityFirebaseValidator.isValid(localityFirebase)
            ) {
              localities.push(new Locality(localityFirebase));
            } else {
              //TODO: report that to Stackdriver
              console.error(
                `could not parse locality at path ${localitySnapshot.ref.path}`
              );
            }
          }

          resolve(localities);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  getCurrentTimestamp = (): Timestamp => {
    return this.firebaseNamespace.firestore.Timestamp.now();
  };

  getTimestampFromMillis = (millis: number): Timestamp => {
    return this.firebaseNamespace.firestore.Timestamp.fromMillis(millis);
  };

  getOrganizationsSnapshot = () => this.db.collection("organizations").get();

  getOrganizationSnapshot = (organizationId: string) =>
    this.db.collection("organizations").doc(organizationId).get();

  getLocalitiesSnapshot = (organizationId: string) =>
    this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("localities")
      .get();

  getLocalitySnapshot = (organizationId: string, localityId: string) =>
    this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("localities")
      .doc(localityId)
      .get();

  getLocalityStatsSnapshot = (
    organizationId: string,
    localityId: string,
    fromDate: string,
    toDate: string
  ) =>
    this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("localities")
      .doc(localityId)
      .collection("stats")
      .where("date", ">=", fromDate)
      .where("date", "<=", toDate)
      .get();

  getFishImagesSnapshot = (
    organizationId: string,
    localityId: string,
    untilTimestamp: Timestamp,
    cageId?: string,
    status?: string
  ) => {
    const amount = 20;
    let query = this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("localities")
      .doc(localityId)
      .collection("images")
      .where("date", "<=", untilTimestamp);
    if (status) {
      query = query.where("status", "==", status);
    }
    if (cageId) {
      query = query.where("cageId", "==", cageId);
    }
    return query.orderBy("date", "desc").limit(amount).get();
  };

  getLocalitySensorsSnapshot = (localityId: string) =>
    this.db.collection("sensors").where("localityId", "==", localityId).get();

  getLocalityImageUrl = (path: string): Promise<string> => {
    const pathReference = this.storage.ref(path);
    return pathReference.getDownloadURL();
  };

  getStorageUrlForPath = (path: string): Promise<string> => {
    const pathReference = this.storage.ref(path);
    return pathReference.getDownloadURL();
  };

  usersFunction = () => this.functions.httpsCallable("getAllUsers");
  addOrganizationClaimFunction = () =>
    this.functions.httpsCallable("addOrganizationClaim");
  addLocalitiesClaimFunction = () =>
    this.functions.httpsCallable("addLocalitiesClaim");
  removeClaimFunction = () => this.functions.httpsCallable("removeClaim");
  addUserHasChangedPasswordClaimFunction = () =>
    this.functions.httpsCallable("addUserHasChangedPasswordClaim");
  setSelectedCage = () => this.functions.httpsCallable("setSelectedCage");
}

let firebase: FirebaseClient;

//app is a namespace, its legacy and unsuported to be typed in babel-typescript implementation. Can't find a way to type it properly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFirebase(firebaseNamespace: any) {
  try {
    if (!firebase) {
      firebase = new FirebaseClient(firebaseNamespace);
    }

    return firebase;
  } catch (error) {
    throw new Error(ERROR_MSG_FIREBASE_INIT_ERROR);
  }
}
