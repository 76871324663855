import { cageWithWorstStatus } from '../logic/cageUtils';
import {
  SummaryStatFirebase,
  CageFirebase,
  LocalityFirebase,
  WelfareStatusFirebase,
  WelfareStatusTrendFirebase,
  FishImagesSummaryFirebase,
  FishImageStatusFirebase
} from './modelsFirebase';
import { Timestamp } from '@google-cloud/firestore';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';

export enum SummaryStatType {
  eyeDamage = 'eyeDamage',
  snoutDamage = 'snoutDamage',
  skinScratches = 'skinScratches',
  wound = 'wound',
  skinDamage = 'skinDamage',
  labridae = 'labridae',
  gillDamage = 'gillDamage',
  tailDamage = 'tailDamage',
  deformity = 'deformity',
  thinFish = 'thinFish',
  grownUpFemale = 'grownUpFemale',
  movable = 'movable',
  stationary = 'stationary',
  fish = 'fish',
  weight = 'weight'
}

export class SummaryStat {
  readonly type: SummaryStatType | null;
  readonly value: number | null;

  constructor(summaryStatFirebase: SummaryStatFirebase) {
    this.type = summaryStatFirebase.type in SummaryStatType ? (summaryStatFirebase.type as SummaryStatType) : null;
    this.value = summaryStatFirebase.value;
  }

  typeDisplayName = (): string => {
    switch (this.type) {
      case SummaryStatType.eyeDamage:
        return Localization.getInst().localizedString(LocalizationKey.eyeDamage);
      case SummaryStatType.snoutDamage:
        return Localization.getInst().localizedString(LocalizationKey.snoutDamage);
      case SummaryStatType.skinScratches:
        return Localization.getInst().localizedString(LocalizationKey.skinScratches);
      case SummaryStatType.wound:
        return Localization.getInst().localizedString(LocalizationKey.woundSummaryStats);
      case SummaryStatType.skinDamage:
        return Localization.getInst().localizedString(LocalizationKey.skinDamageSummaryStats);
      case SummaryStatType.labridae:
        return Localization.getInst().localizedString(LocalizationKey.labridae);
      case SummaryStatType.gillDamage:
        return Localization.getInst().localizedString(LocalizationKey.gillDamage);
      case SummaryStatType.tailDamage:
        return Localization.getInst().localizedString(LocalizationKey.tailDamage);
      case SummaryStatType.deformity:
        return Localization.getInst().localizedString(LocalizationKey.deformity);
      case SummaryStatType.thinFish:
        return Localization.getInst().localizedString(LocalizationKey.thinFish);
      case SummaryStatType.grownUpFemale:
        return Localization.getInst().localizedString(LocalizationKey.grownUpFemaleSummaryStats);
      case SummaryStatType.movable:
        return Localization.getInst().localizedString(LocalizationKey.movable);
      case SummaryStatType.stationary:
        return Localization.getInst().localizedString(LocalizationKey.stationary);
      case SummaryStatType.fish:
        return Localization.getInst().localizedString(LocalizationKey.fishSummaryStats);
      case SummaryStatType.weight:
        return Localization.getInst().localizedString(LocalizationKey.averageWeightStats);
      default:
        return Localization.getInst().localizedString(LocalizationKey.unknownStatsType);
    }
  };

  formattedValue = (): string => {
    switch (this.type) {
      case SummaryStatType.fish:
        return this.value != null
          ? this.value.toFixed(0)
          : Localization.getInst().localizedString(LocalizationKey.summaryStatsValueNoDataText);
      case SummaryStatType.weight:
        return this.value != null
          ? `${this.value.toFixed(2)} g`
          : Localization.getInst().localizedString(LocalizationKey.weightSummaryStatsValueNoDataText);
      default:
        return this.value != null
          ? this.value.toFixed(2)
          : Localization.getInst().localizedString(LocalizationKey.summaryStatsValueNoDataText);
    }
  };
}

export class WelfareStatus {
  static unknown = new WelfareStatus(WelfareStatusFirebase.unknown);
  static soso = new WelfareStatus(WelfareStatusFirebase.soso);
  static good = new WelfareStatus(WelfareStatusFirebase.good);
  static bad = new WelfareStatus(WelfareStatusFirebase.bad);

  private value: number;

  constructor(statusFirebase: WelfareStatusFirebase) {
    this.value = this.toNumber(statusFirebase);
  }

  private toNumber = (statusFirebase: WelfareStatusFirebase): number => {
    switch (statusFirebase) {
      case WelfareStatusFirebase.unknown:
        return -1;
      case WelfareStatusFirebase.bad:
        return 0;
      case WelfareStatusFirebase.soso:
        return 1;
      case WelfareStatusFirebase.good:
        return 2;
    }
  };

  toStatusFirebase = (): WelfareStatusFirebase => {
    switch (this.value) {
      case 0:
        return WelfareStatusFirebase.bad;
      case 1:
        return WelfareStatusFirebase.soso;
      case 2:
        return WelfareStatusFirebase.good;
      default:
        return WelfareStatusFirebase.unknown;
    }
  };

  statusDisplayText = (): string => {
    switch (this.toStatusFirebase()) {
      case WelfareStatusFirebase.unknown:
        return Localization.getInst().localizedString(LocalizationKey.statusUnknown);
      case WelfareStatusFirebase.bad:
        return Localization.getInst().localizedString(LocalizationKey.statusBad);
      case WelfareStatusFirebase.soso:
        return Localization.getInst().localizedString(LocalizationKey.statusSoso);
      case WelfareStatusFirebase.good:
        return Localization.getInst().localizedString(LocalizationKey.statusGood);

      default:
        return Localization.getInst().localizedString(LocalizationKey.statusUnknown);
    }
  };

  equals = (otherStatus: WelfareStatus): boolean => {
    return otherStatus.value === this.value;
  };

  isWorseThan = (otherStatus: WelfareStatus): boolean => {
    if (this.equals(WelfareStatus.unknown) || otherStatus.equals(WelfareStatus.unknown)) {
      return false;
    }

    return this.value < otherStatus.value;
  };
}

export class Cage {
  readonly cageId: string;
  readonly cageIndex: number;
  readonly name: string;
  readonly status: WelfareStatus;
  readonly trend: WelfareStatusTrendFirebase;
  readonly fishTrend: WelfareStatusTrendFirebase;
  readonly summaryStats: SummaryStat[] | undefined;
  readonly weeklySummaryStats?: SummaryStat[] | undefined;
  readonly fishImagesSummary?: FishImagesSummaryFirebase | undefined;
  readonly lastUpdated?: string;

  constructor(cageFirebase: CageFirebase) {
    this.cageId = cageFirebase.cageId;
    this.cageIndex = cageFirebase.cageIndex;
    this.name = cageFirebase.name;
    this.status = new WelfareStatus(cageFirebase.status);
    this.trend = cageFirebase.trend;
    this.fishTrend = cageFirebase.fishTrend;
    this.summaryStats = cageFirebase.summaryStats
      ? cageFirebase.summaryStats.map((summaryStatFirebase) => {
          return new SummaryStat(summaryStatFirebase);
        })
      : undefined;
    this.weeklySummaryStats = cageFirebase.weeklySummaryStats
      ? cageFirebase.weeklySummaryStats.map((summaryStatFirebase) => {
          return new SummaryStat(summaryStatFirebase);
        })
      : undefined;
    this.fishImagesSummary = cageFirebase.fishImagesSummary;
    this.lastUpdated = cageFirebase.lastUpdated;
  }
}

export class Locality {
  readonly localityId: string;
  readonly name: string;
  readonly cages: Cage[];
  readonly worstCage: Cage | undefined;
  readonly locationImagePath: string | undefined;
  readonly displayBiomassGraph?: boolean;
  readonly summaryStats: SummaryStat[] | undefined;
  readonly lastUpdated?: string;
  readonly weeklySummaryStats: SummaryStat[] | undefined;
  readonly weeklyLastUpdated?: string;
  readonly fishTrend: WelfareStatusTrendFirebase;
  readonly weightTrend: WelfareStatusTrendFirebase;
  locationImageUrl: string | undefined | null;
  readonly sensorId: string;

  constructor(localityFirebase: LocalityFirebase) {
    this.localityId = localityFirebase.localityId;
    this.name = localityFirebase.name;
    this.displayBiomassGraph = localityFirebase.displayBiomassGraph;
    this.summaryStats = localityFirebase.summaryStats
      ? localityFirebase.summaryStats.map((summaryStatFirebase) => {
          return new SummaryStat(summaryStatFirebase);
      })
      : undefined;
    this.weeklySummaryStats = localityFirebase.weeklySummaryStats
      ? localityFirebase.weeklySummaryStats.map((summaryStatFirebase) => {
          return new SummaryStat(summaryStatFirebase);
        })
      : undefined;
    this.lastUpdated = localityFirebase.lastUpdated;
    this.weeklyLastUpdated = localityFirebase.weeklyLastUpdated;
    this.fishTrend = localityFirebase.fishTrend;
    this.weightTrend = localityFirebase.weightTrend;

    this.cages = localityFirebase.cages.map((cageFirebase) => {
      return new Cage(cageFirebase);
    });
    this.worstCage = cageWithWorstStatus(this.cages);
    this.locationImagePath = localityFirebase.locationImagePath;
    this.sensorId = localityFirebase.sensorId;
  }
}

export interface Sensor {
  id: string;
  organizationId: string;
  localityId: string;
  cageId: string;
}

export interface StatsMeta {
  cageId?: string;
  date: string;
  week?: number;
  year?: number;
}

export interface Stats {
  fish: number;
  grownUpFemale: number;
  movable: number;
  stationary: number;
  eyeDamage: number;
  snoutDamage: number;
  skinScratches: number;
  wound: number;
  skinDamage: number;
  labridae: number;
  gillDamage: number;
  tailDamage: number;
  deformity: number;
  thinFish: number;
  welfareIncidentsSum: number;
  liceSum: number;
  weight: number;
  manualGrownUpFemale: number;
  manualMovable: number;
  manualStationary: number;
  [key: string]: number;
}

export interface WeightDistributionRow {
  bucket: number;
  count: number;
}

export interface WeightDistributionStats {
  weight_distribution: WeightDistributionRow[];
}

export type StatsRow = Stats & StatsMeta & WeightDistributionStats;

export interface FishImage {
  id: string;
  cageId: string;
  date: Timestamp;
  status: FishImageStatusFirebase;
  url: string;
}

export interface FishImagesSummary {
  lastImagesDateCatch: number;
  lastImageDate: Timestamp;
}

export interface Carousel {
  id: string;
  images: FishImage[];
}

export enum StatsIntervalStatus {
  daily = 'daily',
  weekly = 'weekly'
}
