export enum LocalizationKey {
  loading,

  //users list
  users,
  organizationSelectNone,
  selectAllLocalities,

  // change language
  changeLanguagePageTitle,

  //my account
  myAccountTitle,
  passwordResetLinkHasBeenSent,
  forceChangePasswordMessage,

  //admin
  adminPageTitle,
  theAdminPageIsAccessibleByEverySignedInAdminUser,

  //sign in page
  signInTitle,
  signInButtonText,
  forgotPasswordQuestion,

  //reset password
  resetYourPasswordTitle,
  changePasswordTitle,
  currentPassword,
  newPassword,
  repeatNewPassword,
  changePasswordButtonText,
  passwordHasBeenChanged,

  //forms
  password,
  sendResetPasswordEmail,

  //email confirmation
  verifyYourEmailTitle,
  emailConfirmationSent,
  sendConfirmationEmail,

  //nav bar
  navBarSelectLocation,
  menuLanguage,
  menuMyAccount,
  menuAdmin,
  menuSignOut,
  localitiesListFishWelfare,

  localityDetailsFishWelfareIn,
  localityDetailsWeeklyFishWelfareIn,
  localityDetailsFishBiomassIn,
  cagesGridWelfareInYourCages,
  cagesGridBiomassInYourCages,
  cagesGridSeeMore,

  //Day range selector
  dayRangeSelectorCustom,
  dayRangeSelectorLastWeek,
  dayRangeSelectorLastMonth,
  dayRangeSelectorLast6Months,
  dayRangeSelectorLastYear,
  dayRangeSelectorFrom,
  dayRangeSelectorTo,

  //Cages navigation
  cagesNavigationCompareAllCages,
  cagesNavigationSeeCage,

  //Cage liftup

  cageForLiftupButton,
  cageForLiftupText,

  //stats
  eyeDamage,
  snoutDamage,
  skinScratches,
  wound,
  woundSummaryStats,
  skinDamage,
  skinDamageSummaryStats,
  averageWeightStats,
  averageWeightLabel,
  weightDistribution,
  weightDistributionAverage,
  labridae,
  gillDamage,
  tailDamage,
  deformity,
  thinFish,
  grownUpFemale,
  grownUpFemaleSummaryStats,
  movable,
  stationary,
  unknownStatsType,
  welfareIncidentsSum,
  scannedFishCount,
  scannedFishCountBar,
  itemsFoundPerFish,
  dailyStats,
  weeklyStats,
  week,
  manualMovable,
  manualStationary,
  manualGrownUpFemale,
  fishSummaryStats,
  weightSummaryStats,

  //localityCard
  statusGood,
  statusSoso,
  statusBad,
  statusUnknown,
  locationImageAltText,

  //Main panel
  welfareTab,
  welfareInLocalityArea,
  welfareInLocalityAndCage,
  biomassTab,
  biomassInLocalityArea,
  biomassInLocalityAndCage,
  selectValues,
  todaysCatch,
  catchFrom,
  fishes,
  allFish,
  liceFish,
  remarksFish,
  carouselPrevious,
  carouselNext,
  biomassPopupTitle,
  biomassPopupDetails1,
  biomassPopupDetails2,
  biomassPopupDetails3,
  date,

  //Info Popup
  infoPopupButton,
  liceInfoPopupButton,
  biomassInfoPopupButton,

  //buttons
  activateButton,
  cancelButton,

  //errors and warnings
  locationImageNotAvailable,
  weAreHavingIssuesFetchingDataForThisLocationAtTheMoment,
  thisMayBeDueToTheNetworkConnections,
  tryAgainLaterOrContactUsAt,
  thisMayBeDueToTheNetworkConnectionsOrTheCameraLensesMayNeedToBeCleaned,
  cleanTheLensesAndTryAgainLaterOrContactUsAt,
  weCantGetDataFromLocation,
  makeSureTheLenseOfTheCameraIsClean,
  weCantGetDataFromCage,
  weAreHavingIssuesFetchingDataForAllLocationsAtTheMoment,
  thereAreNoLocalitiesAssignedToYourOrganization,
  pleaseContactUsAt,
  noSuchLocality,
  summaryStatsValueNoDataText,
  weightSummaryStatsValueNoDataText,

  //404 page
  pageNotFoundTitle,
  pageDoesNotExist,
  youMayHaveMistypedTheAddressOrThePageMayhaveMoved,
}
