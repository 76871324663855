import { User } from 'firebase';
import admin from 'firebase-admin';
import { Timestamp } from '@google-cloud/firestore';

export interface OrganizationFirebase {
  organizationId: string;
  name: string;
  logoPath?: string;
}

export interface AuthUser {
  authChecked: boolean;
  user?: User | null;
  hasChangedPassword?: boolean;
  organizationId?: string | null;
  isModerator?: boolean;
  localityIds?: string[] | null;
}

export interface AdminUserRecord extends admin.auth.UserRecord {
  customClaims?: {
    organizationId?: string;
    localities?: { [key: string]: boolean };
    isModerator?: boolean;
  };
}

export interface SummaryStatFirebase {
  type: string;
  value: number | null;
}

export interface CageFirebase {
  cageId: string;
  cageIndex: number;
  name: string;
  status: WelfareStatusFirebase;
  trend: WelfareStatusTrendFirebase;
  fishTrend: WelfareStatusTrendFirebase;
  fishImagesSummary: FishImagesSummaryFirebase;
  summaryStats: SummaryStatFirebase[] | undefined;
  lastUpdated?: string;
  weeklySummaryStats?: SummaryStatFirebase[] | undefined;
  weeklyLastUpdated?: string;
}

export interface LocalityFirebase {
  localityId: string;
  name: string;
  cages: CageFirebase[];
  grownUpFemaleLimits: StatusLimitsFirebase;
  locationImagePath: string | undefined;
  displayBiomassGraph?: boolean;
  summaryStats: SummaryStatFirebase[] | undefined;
  lastUpdated?: string;
  weeklySummaryStats?: SummaryStatFirebase[] | undefined;
  weeklyLastUpdated?: string;
  fishTrend: WelfareStatusTrendFirebase;
  weightTrend: WelfareStatusTrendFirebase;
  sensorId: string;
}

export interface SensorFirebase {
  organizationId: string;
  localityId: string;
  cageId: string;
}

export enum WelfareStatusFirebase {
  unknown = 'unknown',
  good = 'good',
  soso = 'soso',
  bad = 'bad'
}

export enum WelfareStatusTrendFirebase {
  up = 'up',
  noChange = 'noChange',
  down = 'down',
  unknown = 'unknown'
}

// export enum FishStatusTrendFirebase {
//   up = 'up',
//   noChange = 'noChange',
//   down = 'down',
//   unknown = 'unknown'
// }
//
// export enum WeightStatusTrendFirebase {
//   up = 'up',
//   noChange = 'noChange',
//   down = 'down',
//   unknown = 'unknown'
// }

export interface StatusLimitsFirebase {
  mid: number;
  max: number;
}

export enum FishImageStatusFirebase {
  noIssues = 'noIssues',
  lice = 'lice',
  wefare = 'welfare',
  both = 'both'
}

export interface FishImageStatsFirebase {
  grownUpFemale: number;
  movable: number;
  stationary: number;
  eyeDamage: number;
  snoutDamage: number;
  skinScratches: number;
  wound: number;
  skinDamage: number;
  labridae: number;
  gillDamage: number;
  tailDamage: number;
  deformity: number;
  thinFish: number;
}

export interface FishImageFirebase {
  cageId: string;
  date: Timestamp;
  status: FishImageStatusFirebase;
  stats: FishImageStatsFirebase;
  url: string;
}

export interface FishImagesSummaryFirebase {
  lastImagesDateCatch: number;
  lastUpdated: Timestamp;
  lastImageDate: Timestamp;
}
