import { createMuiTheme } from '@material-ui/core/styles';
import 'typeface-raleway';
import { Spacing, SpacingArgument } from '@material-ui/core/styles/createSpacing';
import { RemSpacing, remSpacing } from './RemSpacing';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    remSpacing: RemSpacing;
    colors: {
      iron: string;
      mossGreen: string;
      seaGreen: string;
      red: string;
      blue: string;
      oxfordBlue: string;
      oxfordBlue01: string;
      oxfordBlue02: string;
      oxfordBlue04: string;
      oxfordBlue05: string;
      oxfordBlue06: string;
      transparent: string;
      whiteSmoke: string;
      white: string;
      white08: string;
      black: string;
      darkPurple?: string;
    };
    shadow: {
      small: string;
      medium: string;
      big: string;
      mediumOxfordBlue02: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    remSpacing?: RemSpacing;
    colors?: {
      iron?: string;
      mossGreen?: string;
      seaGreen?: string;
      red?: string;
      blue?: string;
      oxfordBlue?: string;
      oxfordBlue01?: string;
      oxfordBlue02?: string;
      oxfordBlue04?: string;
      oxfordBlue05?: string;
      oxfordBlue06?: string;
      transparent?: string;
      whiteSmoke?: string;
      white?: string;
      white08?: string;
      black?: string;
      darkPurple?: string;
    };
    shadow?: {
      small?: string;
      medium?: string;
      big?: string;
      mediumOxfordBlue02?: string;
    };
  }
}

// A custom theme for this app
export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Raleway',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: {
      fontSize: '1.375rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '1.375rem',
      fontWeight: 400
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 700
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400
    },
    h5: {
      fontSize: '0.75rem',
      fontWeight: 700
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 400
    }
  },
  spacing: ((value: SpacingArgument): number => (value as number) * 4) as Spacing,
  remSpacing: remSpacing,
  colors: {
    iron: 'rgb(223, 225, 229)',
    mossGreen: 'rgba(190, 226, 182, 0.9)',
    seaGreen: 'rgb(169, 217, 159)',
    red: 'rgb(210, 78, 66)',
    blue: 'rgb(0, 109, 157)',
    oxfordBlue: 'rgb(46, 60, 75)', // #2E3C4B
    oxfordBlue01: 'rgba(46, 60, 75, 0.1)', // #2E3C4B, transparency 0.1
    oxfordBlue02: 'rgba(46, 60, 75, 0.2)', // #2E3C4B, transparency 0.2
    oxfordBlue04: 'rgba(46, 60, 75, 0.4)', // #2E3C4B, transparency 0.4
    oxfordBlue05: 'rgba(46, 60, 75, 0.5)', // #2E3C4B, transparency 0.5
    oxfordBlue06: 'rgba(46, 60, 75, 0.6)', // #2E3C4B, transparency 0.6
    transparent: 'transparent',
    whiteSmoke: 'rgb(245,245,245)',
    white: 'rgb(255, 255, 255)',
    white08: 'rgba(255, 255, 255, 0.8)',
    black: 'rgba(0, 0, 0)',
    darkPurple: '#2E3C4B'
  },
  shadow: {
    small: '0px 0px 2px rgba(0, 0, 0, 0.1)',
    medium: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    big: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    mediumOxfordBlue02: '0px 1px 4px rgba(46, 60, 75, 0.2)'
  },
  palette: {
    primary: { main: '#000000' },
    text: {
      primary: 'rgb(46, 60, 75)' // #2E3C4B
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': { fontFamily: 'Raleway' }
      }
    },
    MuiCard: {
      root: {
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)'
      }
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h3',
        h5: 'h3',
        h6: 'h3'
      }
    }
  }
});
