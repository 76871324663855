// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-home-tsx": () => import("./../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-language-tsx": () => import("./../src/pages/language.tsx" /* webpackChunkName: "component---src-pages-language-tsx" */),
  "component---src-pages-locality-details-tsx": () => import("./../src/pages/locality-details.tsx" /* webpackChunkName: "component---src-pages-locality-details-tsx" */),
  "component---src-pages-pw-forget-tsx": () => import("./../src/pages/pw-forget.tsx" /* webpackChunkName: "component---src-pages-pw-forget-tsx" */)
}

