import {
  FishImagesSummaryFirebase,
  CageFirebase,
  WelfareStatusFirebase,
  WelfareStatusTrendFirebase,
//   FishStatusTrendFirebase,
//   WeightStatusTrendFirebase,
  StatusLimitsFirebase,
  LocalityFirebase
} from './modelsFirebase';

class BasicTypesValidator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isValidNumber = (value: any) => {
    return typeof value === 'number';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isValidString = (value: any) => {
    return typeof value === 'string';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isValidBoolean = (value: any) => {
    return typeof value === 'boolean';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isValidArray = (value: any) => {
    return value && typeof value === 'object' && value.constructor === Array;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isValidEnumValue = (enumType: any, value: any) => {
    return Object.values(enumType).includes(value);
  };
}

class FishImagesSummaryFirebaseValidator {
  static isValid = (fishImagesSummaryFirebase: FishImagesSummaryFirebase): boolean => {
    if (!fishImagesSummaryFirebase) {
      return false;
    }

    return (
      BasicTypesValidator.isValidNumber(fishImagesSummaryFirebase.lastImagesDateCatch) &&
      fishImagesSummaryFirebase.lastUpdated != undefined && //can't check if it's an instance of Timestamp, compiler error
      fishImagesSummaryFirebase.lastImageDate != undefined //can't check if it's an instance of Timestamp, compiler error
    );
  };
}

class CageFirebaseValidator {
  static isValidCagesArray = (cagesFirebase: CageFirebase[]) => {
    if (!cagesFirebase) {
      return false;
    }

    return (
      BasicTypesValidator.isValidArray(cagesFirebase) &&
      cagesFirebase.reduce((accumulator, cage) => {
        return accumulator && CageFirebaseValidator.isValid(cage);
      }, true)
    );
  };

  static isValid = (cageFirebase: CageFirebase): boolean => {
    if (!cageFirebase) {
      return false;
    }

    return (
      BasicTypesValidator.isValidString(cageFirebase.cageId) &&
      BasicTypesValidator.isValidNumber(cageFirebase.cageIndex) &&
      BasicTypesValidator.isValidString(cageFirebase.name) &&
//       BasicTypesValidator.isValidEnumValue(FishStatusTrendFirebase, cageFirebase.fishTrend) &&
//       BasicTypesValidator.isValidEnumValue(WeightStatusTrendFirebase, cageFirebase.weightTrend) &&
      FishImagesSummaryFirebaseValidator.isValid(cageFirebase.fishImagesSummary)
    );
  };
}

class StatusLimitsFirebaseValidator {
  static isValid = (statusLimitsFirebase: StatusLimitsFirebase): boolean => {
    if (!statusLimitsFirebase) {
      return false;
    }

    return (
      BasicTypesValidator.isValidNumber(statusLimitsFirebase.mid) &&
      BasicTypesValidator.isValidNumber(statusLimitsFirebase.max)
    );
  };
}

class DisplayBiomassGraohValidator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isValid = (displayBiomassGraph: any) =>
    displayBiomassGraph === undefined || BasicTypesValidator.isValidBoolean(displayBiomassGraph);
}

export class LocalityFirebaseValidator {
  static isValid = (localityFirebase: LocalityFirebase): boolean => {
    if (!localityFirebase) {
      return false;
    }

    return (
      BasicTypesValidator.isValidString(localityFirebase.localityId) &&
      BasicTypesValidator.isValidString(localityFirebase.name) &&
      CageFirebaseValidator.isValidCagesArray(localityFirebase.cages) &&
      DisplayBiomassGraohValidator.isValid(localityFirebase.displayBiomassGraph)
    );
  };
}
