import { LocalizationKey } from "../LocalizationKey";
export const langNo = {
  [LocalizationKey.loading]: "Laster...",

  //users list
  [LocalizationKey.users]: "Brukere",
  [LocalizationKey.organizationSelectNone]: "none",
  [LocalizationKey.selectAllLocalities]: "Alle lokaliteter",

  // change language
  [LocalizationKey.changeLanguagePageTitle]: "Skift språk",

  //my account
  [LocalizationKey.myAccountTitle]: "KONTO",
  [LocalizationKey.passwordResetLinkHasBeenSent]: "",
  [LocalizationKey.forceChangePasswordMessage]: "Du må endre passordet ditt.",

  //admin
  [LocalizationKey.adminPageTitle]: "Admin",
  [LocalizationKey.theAdminPageIsAccessibleByEverySignedInAdminUser]:
    "Admin siden vises kun for brukere som er admins",

  //sign in page
  [LocalizationKey.signInTitle]: "Logg inn",
  [LocalizationKey.signInButtonText]: "Logg inn",
  [LocalizationKey.forgotPasswordQuestion]: "Glemt passord?",

  //reset password
  [LocalizationKey.resetYourPasswordTitle]: "Endre passord",
  [LocalizationKey.changePasswordTitle]: "Endre passord",
  [LocalizationKey.currentPassword]: "Nåværende passord",
  [LocalizationKey.newPassword]: "Nytt passord",
  [LocalizationKey.repeatNewPassword]: "Repeter nytt passord",
  [LocalizationKey.changePasswordButtonText]: "Endre passord",
  [LocalizationKey.passwordHasBeenChanged]: "Passordet har blitt endret",

  //forms
  [LocalizationKey.password]: "passord",
  [LocalizationKey.sendResetPasswordEmail]: "Send mail for å endre passord",

  //email confirmation
  [LocalizationKey.verifyYourEmailTitle]: "Verifiser din e-postadresse",
  [LocalizationKey.emailConfirmationSent]:
    "Bekreftelse sendt på e-post: Sjekk innboksen din (sjekk også under spam/søppel-post) for en e-post fra oss. Last inn denne siden på nytt når du har bekreftet e-posten din.",
  [LocalizationKey.sendConfirmationEmail]: "Send e-postbekreftelse",

  //nav bar
  [LocalizationKey.navBarSelectLocation]: "Velg lokalitet",
  [LocalizationKey.menuLanguage]: "Skift språk",
  [LocalizationKey.menuMyAccount]: "Min konto",
  [LocalizationKey.menuAdmin]: "Admin",
  [LocalizationKey.menuSignOut]: "Logg ut",
  [LocalizationKey.localitiesListFishWelfare]: "CView LiftUp Scanner",

  [LocalizationKey.localityDetailsFishWelfareIn]: "CView LiftUp Scanner",
  [LocalizationKey.localityDetailsWeeklyFishWelfareIn]:
    "WEEKLY FISH WELFARE IN",
  [LocalizationKey.localityDetailsFishBiomassIn]: "BIOMASSE I",
  [LocalizationKey.cagesGridWelfareInYourCages]: "Velferd i dine merder",
  [LocalizationKey.cagesGridBiomassInYourCages]: "Biomasse i dine merder",
  [LocalizationKey.cagesGridSeeMore]: "Se mer",

  //Day range selector
  [LocalizationKey.dayRangeSelectorCustom]: "Egendefinert",
  [LocalizationKey.dayRangeSelectorLastWeek]: "Siste uke",
  [LocalizationKey.dayRangeSelectorLastMonth]: "Siste måned",
  [LocalizationKey.dayRangeSelectorLast6Months]: "Siste 6 måneder",
  [LocalizationKey.dayRangeSelectorLastYear]: "Siste år",
  [LocalizationKey.dayRangeSelectorFrom]: "Fra",
  [LocalizationKey.dayRangeSelectorTo]: "Til",

  //Cages navigation
  [LocalizationKey.cagesNavigationCompareAllCages]: "Sammenligne alle merder",
  [LocalizationKey.cagesNavigationSeeCage]: "Se merd {0}",

  //Cage liftup
  [LocalizationKey.cageForLiftupButton]: "Start LiftUp",
  [LocalizationKey.cageForLiftupText]: "Sett {0} som aktiv for LiftUp Scanner",

  //stats
  [LocalizationKey.eyeDamage]: "Øyeskade",
  [LocalizationKey.snoutDamage]: "Snuteskade",
  [LocalizationKey.skinScratches]: "Skjelltap",
  [LocalizationKey.wound]: "Sår",
  [LocalizationKey.woundSummaryStats]: "Sår",
  [LocalizationKey.skinDamage]: "Hudskade",
  [LocalizationKey.skinDamageSummaryStats]: "Hudskade",
  [LocalizationKey.averageWeightStats]: "Gjennomsnittsvekt",
  [LocalizationKey.averageWeightLabel]: "Vekt (g)",
  [LocalizationKey.weightDistribution]: "Vektfordeling",
  [LocalizationKey.weightDistributionAverage]: "Gjennomsnitt {0}(g)",
  [LocalizationKey.labridae]: "Labridae",
  [LocalizationKey.gillDamage]: "Gjelleskade",
  [LocalizationKey.tailDamage]: "Finneskade",
  [LocalizationKey.deformity]: "Deformitet",
  [LocalizationKey.thinFish]: "Avmagret",
  [LocalizationKey.grownUpFemale]: "Kjønnsmoden hunnlus",
  [LocalizationKey.grownUpFemaleSummaryStats]: "Lus",
  [LocalizationKey.movable]: "Bevegelig lus",
  [LocalizationKey.stationary]: "Fastsittende lus",
  [LocalizationKey.unknownStatsType]: "ukjent",
  [LocalizationKey.welfareIncidentsSum]: "Velferd",
  [LocalizationKey.scannedFishCount]: "Antall telte fisk",
  [LocalizationKey.scannedFishCountBar]: "Telte fisk",
  [LocalizationKey.itemsFoundPerFish]: "Antall tags per fisk",
  [LocalizationKey.dailyStats]: "daily",
  [LocalizationKey.weeklyStats]: "weekly",
  [LocalizationKey.week]: "week",
  [LocalizationKey.manualMovable]: "Manual movable lice",
  [LocalizationKey.manualStationary]: "Manual stationary lice",
  [LocalizationKey.manualGrownUpFemale]: "Manual grown up female",
  [LocalizationKey.date]: "Dato",
  [LocalizationKey.fishSummaryStats]: "No. Dødfisk",
  [LocalizationKey.weightSummaryStats]: "Avg. Vekt",

  //localityCard
  [LocalizationKey.statusGood]: "Gladlaks!",
  [LocalizationKey.statusSoso]: "Vurder tiltak",
  [LocalizationKey.statusBad]: "Vurder tiltak",
  [LocalizationKey.statusUnknown]: "Ukjent status",
  [LocalizationKey.locationImageAltText]: "Lokalitetens posisjon",

  //main panel
  [LocalizationKey.welfareTab]: "Velferd",
  [LocalizationKey.welfareInLocalityArea]: "Velferd i {0}", // locality name
  [LocalizationKey.welfareInLocalityAndCage]: "Velferd i {0}, {1}", // cage id, locality name

  [LocalizationKey.biomassTab]: "Biomasse",
  [LocalizationKey.biomassInLocalityArea]: "Biomasse i {0}", // locality name
  [LocalizationKey.biomassInLocalityAndCage]: "Biomasse i {0}, {1}", // cage id, locality name

  [LocalizationKey.selectValues]: "Velg verdi ({0})", // selected values count
  [LocalizationKey.todaysCatch]: "Dagens fangst",
  [LocalizationKey.catchFrom]: "Bilder fra {0}", // date
  [LocalizationKey.fishes]: "fisker",
  [LocalizationKey.allFish]: "Alle",
  [LocalizationKey.liceFish]: "Fisk med lus",
  [LocalizationKey.remarksFish]: "Fisk med avvik",
  [LocalizationKey.carouselPrevious]: "Forrige",
  [LocalizationKey.carouselNext]: "Neste",

  [LocalizationKey.biomassPopupTitle]: "Vektfordeling i {0}",
  [LocalizationKey.biomassPopupDetails1]: "Vektfordeling i {0}",
  [LocalizationKey.biomassPopupDetails2]: "Fisk: {0}, Vekt: {1} g",
  [LocalizationKey.biomassPopupDetails3]: "Histogram bin size: {0} g",

  //info popup
  [LocalizationKey.infoPopupButton]: "Om vurdering av fiskevelferd",
  [LocalizationKey.liceInfoPopupButton]: "Om lusetelling",
  [LocalizationKey.biomassInfoPopupButton]: "Om biomassemåling",

  //buttons
  [LocalizationKey.activateButton]: "Aktiver",
  [LocalizationKey.cancelButton]: "Avbryt",

  //errors and warnings
  [LocalizationKey.locationImageNotAvailable]:
    "Lokalitetsbilde ikke tilgjengelig",
  [LocalizationKey.weAreHavingIssuesFetchingDataForThisLocationAtTheMoment]:
    "Vi har problemer med å hente data fra denne lokaliteten for øyeblikket.",
  [LocalizationKey.thisMayBeDueToTheNetworkConnections]:
    "Dette kan skyldes dårlig nettverksforbindelse.",
  [LocalizationKey.tryAgainLaterOrContactUsAt]:
    "Prøv igjen senere eller kontakt support:",
  [LocalizationKey.thisMayBeDueToTheNetworkConnectionsOrTheCameraLensesMayNeedToBeCleaned]:
    "Dette kan skyldes dårlig nettverksforbindelse eller behov for rengjøring av kameralinse.",
  [LocalizationKey.cleanTheLensesAndTryAgainLaterOrContactUsAt]:
    "Rengjør kameralinse eller kontakt support",
  [LocalizationKey.weCantGetDataFromLocation]:
    "Vi kan ikke hente data fra {0}.", // location name
  [LocalizationKey.makeSureTheLenseOfTheCameraIsClean]:
    "Sørg for at kameralinse er ren.",
  [LocalizationKey.weCantGetDataFromCage]:
    "Vi kan ikke hente data fra merd {0}.", // cage id
  [LocalizationKey.weAreHavingIssuesFetchingDataForAllLocationsAtTheMoment]:
    "Vi har problemer med å hente data fra alle lokaliteter for øyeblikket.",
  [LocalizationKey.thereAreNoLocalitiesAssignedToYourOrganization]:
    "Det er ingen lokaliteter registrert under din organisasjon.",
  [LocalizationKey.pleaseContactUsAt]: "Kontakt oss:",
  [LocalizationKey.noSuchLocality]: "Lokaliteten finnes ikke",
  [LocalizationKey.summaryStatsValueNoDataText]: "",
  [LocalizationKey.weightSummaryStatsValueNoDataText]: "-",

  //404 page
  [LocalizationKey.pageNotFoundTitle]: "Not found (404)",
  [LocalizationKey.pageDoesNotExist]: "Siden du ser etter finnes ikke.",
  [LocalizationKey.youMayHaveMistypedTheAddressOrThePageMayhaveMoved]:
    "Det kan skyldes at adressen er skrevet feil eller at siden har blitt flyttet.",
};
