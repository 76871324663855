import { langEn } from './translations/langEn';
import { langNo } from './translations/langNo';
import { LocalizationKey } from './LocalizationKey';
import { LanguageCode } from './LanguageCode';

const defaultLanguage = LanguageCode.en;

export class Localization {
  private static instance: Localization;

  private selectedLanguageCode: LanguageCode;

  private translations = { [LanguageCode.en]: langEn, [LanguageCode.no]: langNo };

  private constructor(languageCode: LanguageCode) {
    this.selectedLanguageCode = languageCode;
  }

  static getInst() {
    if (!Localization.instance) {
      const savedLanguage = this.loadLanguage();
      Localization.instance = new Localization(savedLanguage !== undefined ? savedLanguage : defaultLanguage);
    }
    return Localization.instance;
  }

  private static saveLanguage = (languageCode: LanguageCode) => {
    if (typeof window !== 'undefined') {
      window.localStorage.languageCode = languageCode;
    }
  };

  private static loadLanguage = (): LanguageCode | undefined => {
    if (typeof window !== 'undefined') {
      return window.localStorage.languageCode as LanguageCode;
    }

    return undefined;
  };

  changeLanguage = async (languageCode: LanguageCode) => {
    this.selectedLanguageCode = languageCode;
    Localization.saveLanguage(languageCode);
  };

  getLanguage = (): LanguageCode => {
    return this.selectedLanguageCode;
  };

  private formatString = (format: string, ...args: string[]): string => {
    for (const arg in args) {
      format = format.replace('{' + arg + '}', args[arg]);
    }
    return format;
  };

  localizedString = (key: LocalizationKey, ...args: string[]) => {
    const str = this.translations[this.selectedLanguageCode][key];
    return this.formatString(str, ...args);
  };
}
