import React from 'react';
import { FirebaseContext } from './Context';
import { FirebaseClient } from './Client';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

interface WithFirebaseProps {
  firebase: FirebaseClient;
}

export const withFirebase = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<Omit<P, keyof WithFirebaseProps>> => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => firebase && <WrappedComponent {...(props as P)} firebase={firebase} />}
  </FirebaseContext.Consumer>
);
