import { Cage, FishImagesSummary, SummaryStatType } from '../types/models';
import moment from 'moment';

export const cageWithWorstStatus = (cages: Cage[]): Cage | undefined => {
  if (cages.length == 0) {
    return undefined;
  }

  let worstCageIndex: number | undefined = undefined;
  let worstLiceCount: number | undefined = undefined; // the bigger the worse

  for (let i = 0; i < cages.length; i++) {
    const cage = cages[i];
    if (cage.summaryStats == undefined) {
      return cage;
    }

    const liceStat = cage.summaryStats.find((summaryStat) => {
      return summaryStat.type == SummaryStatType.grownUpFemale;
    });

    if (liceStat == undefined || liceStat.value == null) {
      // We couldn't find grownUpFemale statistics. We assume that this cage is the worst.
      return cage;
    }

    if (worstLiceCount == undefined || liceStat.value > worstLiceCount) {
      worstLiceCount = liceStat.value;
      worstCageIndex = i;
    }
  }

  return worstCageIndex != undefined ? cages[worstCageIndex] : undefined;
};

export const calculateLocalityFishImagesSummary = (cages: Cage[]): FishImagesSummary | undefined => {
  const cagesWithFishImagesSummary = cages.filter((cage) => {
    return cage.fishImagesSummary != undefined;
  });
  if (0 == cagesWithFishImagesSummary.length) {
    return;
  }

  let lastImageDateMillis = 0
  for (let i = 0; i < cages.length; i++) {
    const cage = cages[i];
    if (cage.fishImagesSummary != undefined) {
      if (lastImageDateMillis == 0) {
          const temp = moment(cage.fishImagesSummary.lastImageDate.toMillis()).utc().startOf('day').valueOf();
          lastImageDateMillis = temp;
      } else {
        const cageLastImageDateMillis = moment(cage.fishImagesSummary.lastImageDate).utc().startOf('day').valueOf();
        if (cageLastImageDateMillis > lastImageDateMillis) {
          lastImageDateMillis = cageLastImageDateMillis;

        }
      }
    }
  }

  if (lastImageDateMillis == 0) {
    return;
  }

  return cagesWithFishImagesSummary.reduce(
    (localitySummary, cage) => {
      if (cage.fishImagesSummary != undefined) {
        if (!localitySummary.lastImageDate) {
          localitySummary.lastImageDate = cage.fishImagesSummary.lastImageDate;
        } else {
          const cageLastImageDateMillis = cage.fishImagesSummary.lastImageDate.toMillis();
          const localityLastImageDateMillis = localitySummary.lastImageDate.toMillis();
          localitySummary.lastImageDate =
            cageLastImageDateMillis > localityLastImageDateMillis
              ? cage.fishImagesSummary.lastImageDate
              : localitySummary.lastImageDate;
        }
        //localitySummary.lastImagesDateCatch += cage.fishImagesSummary.lastImagesDateCatch;
        const temp = moment(cage.fishImagesSummary.lastImageDate.toMillis()).utc().startOf('day').valueOf();
        if (temp == lastImageDateMillis) {
          localitySummary.lastImagesDateCatch += cage.fishImagesSummary.lastImagesDateCatch;
        }
      }
      return localitySummary;
    },
    ({ lastImagesDateCatch: 0 } as unknown) as FishImagesSummary
  );
};
