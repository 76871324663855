import { LocalizationKey } from "../LocalizationKey";
export const langEn = {
  [LocalizationKey.loading]: "Loading...",

  //users list
  [LocalizationKey.users]: "Users",
  [LocalizationKey.organizationSelectNone]: "none",
  [LocalizationKey.selectAllLocalities]: "All localities",

  // change language
  [LocalizationKey.changeLanguagePageTitle]: "Change language",

  //my account
  [LocalizationKey.myAccountTitle]: "ACCOUNT",
  [LocalizationKey.passwordResetLinkHasBeenSent]: "",
  [LocalizationKey.forceChangePasswordMessage]:
    "Default password has to be changed.",

  //admin
  [LocalizationKey.adminPageTitle]: "Admin",
  [LocalizationKey.theAdminPageIsAccessibleByEverySignedInAdminUser]:
    "The Admin Page is accessible by every signed in admin user.",

  //sign in page
  [LocalizationKey.signInTitle]: "Sign in",
  [LocalizationKey.signInButtonText]: "Sign In",
  [LocalizationKey.forgotPasswordQuestion]: "Forgot password?",

  //reset password
  [LocalizationKey.resetYourPasswordTitle]: "Reset your password",
  [LocalizationKey.changePasswordTitle]: "Change your password",
  [LocalizationKey.currentPassword]: "Current password",
  [LocalizationKey.newPassword]: "New password",
  [LocalizationKey.repeatNewPassword]: "Repeat new password",
  [LocalizationKey.changePasswordButtonText]: "Change password",
  [LocalizationKey.passwordHasBeenChanged]: "Password has been changed",

  //forms
  [LocalizationKey.password]: "password",
  [LocalizationKey.sendResetPasswordEmail]: "Send reset password e-mail",

  //email confirmation
  [LocalizationKey.verifyYourEmailTitle]: "Verify your e-mail",
  [LocalizationKey.emailConfirmationSent]:
    "E-mail confirmation sent: Check you e-mails (Spam folder included) for a confirmation e-mail. Refresh this page once you confirmed your e-mail.",
  [LocalizationKey.sendConfirmationEmail]: "Send confirmation e-mail",

  //nav bar
  [LocalizationKey.navBarSelectLocation]: "Select location",
  [LocalizationKey.menuLanguage]: "Change language",
  [LocalizationKey.menuMyAccount]: "My account",
  [LocalizationKey.menuAdmin]: "Admin",
  [LocalizationKey.menuSignOut]: "Sign out",
  [LocalizationKey.localitiesListFishWelfare]: "CView LiftUp Scanner",

  [LocalizationKey.localityDetailsFishWelfareIn]: "CView LiftUp Scanner",
  [LocalizationKey.localityDetailsWeeklyFishWelfareIn]:
    "WEEKLY FISH WELFARE IN",
  [LocalizationKey.localityDetailsFishBiomassIn]: "FISH BIOMASS IN",
  [LocalizationKey.cagesGridWelfareInYourCages]: "Status in cages",
  [LocalizationKey.cagesGridBiomassInYourCages]: "Biomass in your cages",
  [LocalizationKey.cagesGridSeeMore]: "See more",

  //Day range selector
  [LocalizationKey.dayRangeSelectorCustom]: "Custom",
  [LocalizationKey.dayRangeSelectorLastWeek]: "Last week",
  [LocalizationKey.dayRangeSelectorLastMonth]: "Last month",
  [LocalizationKey.dayRangeSelectorLast6Months]: "Last 6 months",
  [LocalizationKey.dayRangeSelectorLastYear]: "Last year",
  [LocalizationKey.dayRangeSelectorFrom]: "From",
  [LocalizationKey.dayRangeSelectorTo]: "To",

  //Cages navigation
  [LocalizationKey.cagesNavigationCompareAllCages]: "Compare all cages",
  [LocalizationKey.cagesNavigationSeeCage]: "See cage {0}",

  //Cage liftup
  [LocalizationKey.cageForLiftupButton]: "Start LiftUp",
  [LocalizationKey.cageForLiftupText]: "Set {0} as active for LiftUp Scanner",

  //stats
  [LocalizationKey.eyeDamage]: "Eye damage",
  [LocalizationKey.snoutDamage]: "Snout damage",
  [LocalizationKey.skinScratches]: "Skin scratches",
  [LocalizationKey.wound]: "Wound",
  [LocalizationKey.woundSummaryStats]: "Injuries",
  [LocalizationKey.skinDamage]: "Skin damage",
  [LocalizationKey.skinDamageSummaryStats]: "Scales loss",
  [LocalizationKey.averageWeightStats]: "Average weight",
  [LocalizationKey.averageWeightLabel]: "Weight (g)",
  [LocalizationKey.weightDistribution]: "Weight distribution",
  [LocalizationKey.weightDistributionAverage]: "Average {0}(g)",
  [LocalizationKey.labridae]: "Labridae",
  [LocalizationKey.gillDamage]: "Gill damage",
  [LocalizationKey.tailDamage]: "Tail damage",
  [LocalizationKey.deformity]: "Deformity",
  [LocalizationKey.thinFish]: "Thin fish",
  [LocalizationKey.grownUpFemale]: "Grown up female",
  [LocalizationKey.grownUpFemaleSummaryStats]: "Lice",
  [LocalizationKey.movable]: "Movable lice",
  [LocalizationKey.stationary]: "Stationary lice",
  [LocalizationKey.unknownStatsType]: "unknown",
  [LocalizationKey.welfareIncidentsSum]: "Welfare",
  [LocalizationKey.scannedFishCount]: "Scanned fish count",
  [LocalizationKey.scannedFishCountBar]: "Scanned fish",
  [LocalizationKey.itemsFoundPerFish]: "Items found per fish",
  [LocalizationKey.dailyStats]: "daily",
  [LocalizationKey.weeklyStats]: "weekly",
  [LocalizationKey.week]: "week",
  [LocalizationKey.manualMovable]: "Manual movable lice",
  [LocalizationKey.manualStationary]: "Manual stationary lice",
  [LocalizationKey.manualGrownUpFemale]: "Manual grown up female",
  [LocalizationKey.date]: "Date",
  [LocalizationKey.fishSummaryStats]: "No. Deadfish",
  [LocalizationKey.weightSummaryStats]: "Avg. Weight",

  //localityCard
  [LocalizationKey.statusGood]: "Clapping fins!",
  [LocalizationKey.statusSoso]: "Let's fix this",
  [LocalizationKey.statusBad]: "Let's fix this",
  [LocalizationKey.statusUnknown]: "Unknown status",
  [LocalizationKey.locationImageAltText]: "Location of the locality",

  //main panel
  [LocalizationKey.welfareTab]: "Statistics",
  [LocalizationKey.welfareInLocalityArea]: "Statistics in {0} area", // locality name
  [LocalizationKey.welfareInLocalityAndCage]: "Statistics in {0}, {1}", // cage id, locality name

  [LocalizationKey.biomassTab]: "Biomass",
  [LocalizationKey.biomassInLocalityArea]: "Biomass in {0} area", // locality name
  [LocalizationKey.biomassInLocalityAndCage]: "Biomass in {0}, {1}", // cage id, locality name

  [LocalizationKey.selectValues]: "Select values ({0})", // selected values count
  [LocalizationKey.todaysCatch]: "Today's catch",
  [LocalizationKey.catchFrom]: "Catch from {0}", // date
  [LocalizationKey.fishes]: "fishes",
  [LocalizationKey.allFish]: "All",
  [LocalizationKey.liceFish]: "Fish with lice",
  [LocalizationKey.remarksFish]: "Fish with remarks",
  [LocalizationKey.carouselPrevious]: "Previous",
  [LocalizationKey.carouselNext]: "Next",

  [LocalizationKey.biomassPopupTitle]: "Weight distribution on {0}",
  [LocalizationKey.biomassPopupDetails1]: "Weight distribution on {0}",
  [LocalizationKey.biomassPopupDetails2]: "Fish: {0}, Weight: {1} g",
  [LocalizationKey.biomassPopupDetails3]: "Histogram bin size: {0} g",
  //info popup
  [LocalizationKey.infoPopupButton]: "About counting",
  [LocalizationKey.liceInfoPopupButton]: "About weight estimation", //TODO
  [LocalizationKey.biomassInfoPopupButton]: "About biomass measuring",

  //buttons
  [LocalizationKey.activateButton]: "Activate",
  [LocalizationKey.cancelButton]: "Cancel",

  //errors and warnings
  [LocalizationKey.locationImageNotAvailable]: "Location image not available",
  [LocalizationKey.weAreHavingIssuesFetchingDataForThisLocationAtTheMoment]:
    "We are having issues fetching data for this location at the moment.",
  [LocalizationKey.thisMayBeDueToTheNetworkConnections]:
    "This may be due to the network connections.",
  [LocalizationKey.tryAgainLaterOrContactUsAt]:
    "Try again later or contact us at:",
  [LocalizationKey.thisMayBeDueToTheNetworkConnectionsOrTheCameraLensesMayNeedToBeCleaned]:
    "This may be due to the network connections or the camera lenses may need to be cleaned.",
  [LocalizationKey.cleanTheLensesAndTryAgainLaterOrContactUsAt]:
    "Clean the lenses and try again later, or contact us at:",
  [LocalizationKey.weCantGetDataFromLocation]: "We can’t get data from {0}.", // location name
  [LocalizationKey.makeSureTheLenseOfTheCameraIsClean]:
    "Make sure the lense of the camera is clean.",
  [LocalizationKey.weCantGetDataFromCage]: "We can’t get data from Cage {0}.", // cage id
  [LocalizationKey.weAreHavingIssuesFetchingDataForAllLocationsAtTheMoment]:
    "We are having issues fetching data for all locations at the moment.",
  [LocalizationKey.thereAreNoLocalitiesAssignedToYourOrganization]:
    "There are no localities assigned to your organization.",
  [LocalizationKey.pleaseContactUsAt]: "Please contact us at:",
  [LocalizationKey.noSuchLocality]: "No such locality",
  [LocalizationKey.summaryStatsValueNoDataText]: "",
  [LocalizationKey.weightSummaryStatsValueNoDataText]: "-",

  //404 page
  [LocalizationKey.pageNotFoundTitle]: "Not found (404)",
  [LocalizationKey.pageDoesNotExist]:
    "The page you were looking for doesn't exist.",
  [LocalizationKey.youMayHaveMistypedTheAddressOrThePageMayhaveMoved]:
    "You may have mistyped the address or the page may have moved.",
};
