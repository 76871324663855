import React from 'react';
import { Location } from '@reach/router';
import queryString from 'query-string';

export function withLocation<T>(ComponentToWrap: React.FunctionComponent<T>) {
  return (props: T) => (
    <Location>
      {({ location, navigate }) => (
        <ComponentToWrap
          {...props}
          location={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
        />
      )}
    </Location>
  );
}
