import React, { Fragment } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './../ui-components/Theme';

import { FirebaseContext } from './Firebase/Context';
import { getFirebase } from './Firebase/Client';
import { withAuthentication } from './Session/withAuthentication';
import { Background } from '../ui-components/Background/Background';
import { NavBar } from '../ui-components/NavBar/NavBar';

export default class Layout extends React.Component {
  state = {
    firebase: null
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const firestore = import('firebase/firestore');
    const functions = import('firebase/functions');

    Promise.all([app, auth, firestore, functions]).then(async (values) => {
      const firebase = await getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FirebaseContext.Provider value={this.state.firebase}>
          <AppWithAuthentication {...this.props} />
        </FirebaseContext.Provider>
      </ThemeProvider>
    );
  }
}

const AppWithAuthentication = withAuthentication(({ children }) => {
  return (
    <Background>
      <Fragment>
        <NavBar />
        {children}
      </Fragment>
    </Background>
  );
});
